import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { AuthContext } from "../components/layoutNoStripe";

import {
  Footer,
  NavMenu,
  NavList,
  NavListItem,
  FooterLogoCopyright,
  FooterCopyright,
  FooterHygieneList,
  FooterHygieneListItem,
} from "../styledComponents/footer"
import KarinaGrantLogo from "./logo"
import Icon from "../components/icon"
import { StyledButtonLink } from "../styledComponents/button"

const FooterContent = ({ isCookieSet }) => {
  const authContext = useContext(AuthContext) || {};
  const handleLogin = () => {
    authContext.loginWithRedirect({
      redirectUri: window.location.origin + '/my-account/',
    });
  };
  const {
    wpMenu: {
      menuItems: { nodes },
    },
  } = useStaticQuery(graphql`
    {
      wpMenu(name: { eq: "Footer Menu" }) {
        name
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
                id
              }
            }
            id
          }
        }
      }
    }
  `)

  return (
    <Footer isCookieSet={isCookieSet}>
      <NavMenu>
        {nodes.map(menuItems => {
          if (menuItems.childItems.nodes.length) {
            return (
              <NavList key={menuItems.id} hasChildren>
                {menuItems.childItems.nodes.map(childItem => (
                  <NavListItem key={childItem.id}>
                    <Link to={childItem.url}>{childItem.label}</Link>
                  </NavListItem>
                ))}
              </NavList>
            )
          }
          return null
        })}

        {/* {authContext.isAuthenticated ? 
          <StyledButtonLink to='/my-account'><Icon name="user"/>My Account</StyledButtonLink> : <StyledButtonLink onClick={handleLogin}>Log in</StyledButtonLink>} */}
        {/* {!authContext.isAuthenticated ? (
          <StyledButtonLink className="logout" onClick={identity.logout}>
            Log out
          </StyledButtonLink>
        ) : (
          <StyledButtonAsLink className="logout" onClick={handleLogin}>
            Log in
          </StyledButtonAsLink>
        )} */}
        <FooterLogoCopyright>
          <KarinaGrantLogo color="white" />
          <FooterCopyright>&copy;{new Date().getFullYear()}</FooterCopyright>
        </FooterLogoCopyright>
        <FooterHygieneList>
          <FooterHygieneListItem key="privacy">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </FooterHygieneListItem>
          <FooterHygieneListItem key="terms">
            <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
          </FooterHygieneListItem>
          <FooterHygieneListItem key="cookies">
            <Link to="/cookie-policy">Cookie Policy</Link>
          </FooterHygieneListItem>
        </FooterHygieneList>
      </NavMenu>
    </Footer>
  )
}

export default FooterContent
