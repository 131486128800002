import styled, { css } from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"
import { navFontFamily, regular9, regular14, regular16, regular18, semiboldSecondary14, semibold14, semibold16, semibold18, bold20, bold18, bold16, bold28 } from "./typography"


export const HeaderStyles = css`
  background: url('/top-line.png') repeat-x 0 0;
  background-color: var(--grey-lightest);
  position: relative;
  z-index: 1;
  padding: 0;
  box-shadow: 0px 5px 20px 0px rgb(173 173 173 / 75%);

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 1.6rem calc((24 / 720) * 100vw) 1.6rem;
    min-height: 9.2rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 1.6rem calc((24 / 1024) * 100vw) 1.6rem;
    min-height: 10rem;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    padding: 2.3rem calc((24 / 1024) * 100%) 2.3rem;
    min-height: 11.6rem;
  }
`
const listItem = styled.li`
  display: block;
  position: relative;
  border-bottom: 1px solid var(--grey-lighter);
  background: var(--white);
  @media screen and (min-width: ${breakpoints.sm}px) {
    width: 100%;
    text-align: center;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    text-align: left;
    ${baseGridStyles}
    background: var(--grey-lightest);
  }
  h3 {
    color: var(--black);
  }
  ul li a {
    padding-left: 0;
  }
  a {
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 1.6rem;
    cursor: pointer;
    outline: none;
    white-space: wrap;
    border-bottom: 1px solid var(--white);
    color: var(--light-green-mid);
    ${bold18}
    font-weight: 700;

    &:focus,
    &:visited,
    &:hover {
      border-bottom: 1px solid var(--white);
      color: var(--light-green-mid);
      @media screen and (min-width: ${breakpoints.sm}px) {
        color:var(--light-green-mid);
        border-bottom: 0;
      }
    }

    @media screen and (min-width: ${breakpoints.sm}px) {
      border-bottom: 0;
      ${regular18}
     }
 
     @media screen and (min-width: ${breakpoints.md}px) {
       white-space: wrap;
       font-weight: 700;
     }
  }
  svg {
    fill: var(--white);
    @media screen and (min-width: ${breakpoints.sm}px) {
      fill: var(--light-green-mid);
    }

  }
  .cart-count {
    background: var(--white);
    border-radius: 100px;
    color: var(--light-green-mid);
    ${regular9}
    display: inline-block;
    text-align: center;
    width: 16px;
    position: absolute;
    top: 0.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      background: var(--light-green-mid);
      color: var(--white);
    }
  }
`

const StyledHeader = styled.header`
  ${HeaderStyles}
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 130.4rem;
  justify-content: ${props => props.isLander ? 'center' : 'space-between'};
  margin: 0 auto;
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: grid;
    align-items: center;
  }

  @media screen and (min-width: 1304px) {
    padding: 0;
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    max-width: 100%;
    
  }
`

const LogoButtonContainer = styled.div`
  ${baseGridStyles}
  grid-row-gap: 0;
  padding-top: 1.2rem;
  padding-bottom: 0.8rem;
  width: 100%;
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: inline;
    grid-column: 1 / 3;
    padding: ${props => props.isLander ? '1.6rem 0' : '0'};
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 4;
  }
`

const Logo = styled.h1`
  margin: 0;
  grid-column: 1 / 5;
  order: 1;
  justify-content:  ${props => props.isLander ? 'center' : 'flex-start'};
  display: flex;
  a,
  a:hover,
  a:focus {
    display: block;
    border-bottom: 0;
    align-items: center;
    display: flex;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding-left: 0;
    padding-top: 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding-top: 1.8rem;
  }
`

const Tagline = styled.p`
  grid-column: 1 / 4;
  order: 3;
  margin-top: -1rem;
  color: #009eff;
  letter-spacing: -0.06rem;
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top:-1.7rem
  }
`

const NavButton = styled.button`
  grid-column: 5;
  grid-row: 1 / 3;
  order: 2;
  border: none;
  cursor: pointer;
  outline: none;
  background: ${props => props.hasHero ? 'transparent' : 'var(--light-grey-lightest)'};
  transition: background-color 0.35s ease-in-out;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  z-index: 2;
  svg {
    position: relative;
    top: 0.2rem;
    width: 2.4rem;
    height: 2.4rem;

    path {
      fill: ${props => !props.hasHero ? 'var(--light-green-dark)' : 'var(--light-green-dark)'};
      @media screen and (min-width: ${breakpoints.md}px) {
        fill: var(--white);
      }
    } 
  }
  &:focus,
  &:hover {
    color: var(--grey-darker);
    @media screen and (min-width: ${breakpoints.md}px) {
      background: var(--light-green-darkest);
    }
  }
  .topBun {
    transform: ${props => (props.isParentOpen ? "rotate(45deg)" : "0")};
    transition: all 0.35s ease-in-out;
    transform-origin: 0.1rem 0.4rem;
  }
  .filling {
    opacity: ${props => (props.isParentOpen ? "0" : "1")};
    transition: opacity 0.15s ease-in-out;
  }

  .bottomBun {
    transform: ${props => (props.isParentOpen ? "rotate(-45deg)" : "0")};
    transition: all 0.35s ease-in-out;
    transform-origin: 0 1rem;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    position: absolute;
    right: 3.2rem;
    top: 3.4rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    right: 2.4rem;
    top: 3rem;
    background: var(--light-purple-mid-dark);
    border-radius: 0.8rem;
    padding: 0.8rem;
    width: 10.4rem;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    position: absolute;
    right: 3.2rem;
    top: 3.8rem;
  }


  span {
    display: none;
    @media screen and (min-width: ${breakpoints.sm}px) {
      display: block;
      ${semibold18};
      font-weight: 700;
      margin-left: 1.2rem;
      color: var(--light-green-dark);
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      color: var(--white);
    }
  }
`

const NavMenu = styled.nav`
  max-height: ${props => (props.isParentOpen ? "100vh" : "0")};
  // max-height: ${props => (props.isParentOpen ? "90rem" : "100%")};
  overflow: ${props => (props.isParentOpen ? "auto" : "hidden")};;
  transition: max-height 0.35s;
  background: var(--white);
  width: 100%;
  grid-column: span 8;
  ${props => (props.isParentOpen && "position: absolute;")};
  top: 0;
  left: 0;
  
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    background: transparent;
    ${props => (props.isParentOpen && "position: relative;top: 2rem;border-top: 1px solid var(--grey-light  );")};
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    // max-height: ${props => (props.isParentOpen ? "100%" : "100%")};
    position: relative;top: 2rem;
  }
`

const NavMenuMdLarge = styled.nav`
  display:none;
  @media screen and (min-width: ${breakpoints.sm}px) {
    display:block;
    position: absolute;
    right: 14rem;
    top: 2rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    top: 2.4rem;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    top: 2.8rem;
    right: 16rem;
  }
  ul {
    list-style: none;
    display: flex;
    padding-left: 0;
    li {
      margin 0 0.8rem;
      @media screen and (min-width: ${breakpoints.md}px) {
        margin 0 1.6rem;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        margin 0 2.4rem;
      }

      a, button {
        display: inline-flex;
        border-bottom: 0;
        ${navFontFamily}
        font-weight: 700;

        &:visited {
          color: var(--light-purple-darker);
        }
        &:hover {
          color: var(--light-green-darkest);
        }
        
      }
    }
  }
`

const ParentNavList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 0;
  background: var(--white);
  transition: border-top 0.2s ease-in-out;
  ${navFontFamily}
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding-top: 2rem;
    background: var(--grey-lightest);
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    width: auto;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: 0;
    padding-top: 0;
    
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    margin-top: 0;
  }
`

const ParentNavListItem = styled(listItem)`
  position: relative;
  &:nth-child(6),
  &:nth-child(14),
  &:nth-child(18),
  &:nth-child(19){
    border-top: 0.5rem solid var(--grey-lighter);
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 0;
    max-width: 100%;
    grid-row-gap: 0;
  }
  [aria-current]:not([aria-current="false"]) {
    text-decoration: underline;
  }
    &.login-out {
      text-align:center;
      background-color: var(--light-purple-darker);

      button {
        padding: 1.6rem 1.4rem;
        font-weight: bold;
        text-align: center;
        border-bottom: 0;
        color: #fff;
        width: 100%;
      }
    }
  h2 {
    ${bold28}
    &:nth-of-type(2){
      grid-row: 1;
      margin: 1.6rem 0 0;
      grid-column: 5 / 8;
    }
  }
  a {
    ${semibold16}
    font-weight: 700;

    @media screen and (min-width: ${breakpoints.sm}px) {
      padding: 1.1rem 0.6rem;
      ${navFontFamily}
      ${bold18}
      &:focus,
      &:hover,
      &.active:hover,
      &.active:focus {
        /* background: var(--grey-lighter); */
        /* color: var(--light-green-dark); */
      }
      &.active,
      &.active:hover,
      &.active:focus {
        ${semiboldSecondary14};
        background: transparent;
        cursor: default;
      }
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      ${navFontFamily};
      padding: 1.2rem 1.6rem;
      
      ${bold18}
      ${navFontFamily}
      font-weight: 700;
      &:focus,
      &:hover,
      &.active:hover,
      &.active:focus {
        color: ${props => (props.hasHero ? "#ffffff8c" : "var(--light-green-dark)")};
        font-weight: 700;
      }

      &.active,
      &.active:hover,
      &.active:focus {
        background: transparent;
        font-weight: 700;
      }
    }

    @media screen and (min-width: ${breakpoints.l}px) {
      ${navFontFamily};
      padding: 1.2rem 1.6rem;
      
      ${bold20}
      ${navFontFamily}
      font-weight: 700;
      &:focus,
      &:hover,
      &.active:hover,
      &.active:focus {
        color: ${props => (props.hasHero ? "#ffffff8c" : "var(--light-green-dark)")};
        font-weight: 700;
      }

      &.active,
      &.active:hover,
      &.active:focus {
        background: transparent;
        font-weight: 700;
      }
    }
  }


`

const ChildNavListItemButton = styled.button`
  display: none;
  width: 100%;
  padding: 1.6rem;
  cursor: pointer;
  border: none;
  border-radius: 8px 8px 0 0;
  background: ${props => props.isChildMenuOpen ? 'var(--grey-lightest)' : 'transparent'};
  color: var(--light-green-mid);
  outline: none;
  white-space: nowrap;
  ${navFontFamily}
  &:focus,
  &:hover {
    color: var(--light-green-lighter);
  }

  &:hover {
    @media screen and (min-width: ${breakpoints.sm}px) {
      .button-text {
        color: var(--light-green-lighter);
      } 
      svg path {
      fill: var(--light-green-light);
      }
    }
  }

  .button-text {
    margin-right: 1rem;
    color: var(--light-grey-mid);
    @media screen and (min-width: ${breakpoints.sm}px) {
      font-weight: 700;
    }
    
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    display: block;
    padding: 1.1rem 0.6rem;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 1.2rem 1.6rem;
  }
`

const ChildNavList = styled.ul`
  @media screen and (min-width: ${breakpoints.md}px) {
    padding-left: 0;
    margin-bottom: 0;
  }
  &:nth-child(1) {
    li:last-of-type {
      border-bottom: 0px;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 5;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      grid-column: span 5;
    }
  }
  &:nth-child(2) {
    li:last-of-type {
      border-bottom: 0px;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 1 / 5;
      margin-top: 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      grid-column: 1 / 4;
    }
  }
  &:nth-child(3) {
    li:last-of-type {
      border-bottom: 0px;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-row: auto;
      grid-row-start: 1;
      grid-column: 5 / 8;
      margin-top: 0;
    }
  }

  &:nth-child(4) {
    li:last-of-type {
      border-bottom: 0px;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-top: 0;
      grid-column: 5 / 9;
    }
  }

  &:nth-child(5) {
    li:last-of-type {
      border-bottom: 0px;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-top: 0;
      grid-column: 9 / 13;
    }
  }
`

const ChildNavListItem = styled(listItem)`

  @media screen and (min-width: ${breakpoints.md}px) {
    display: block;
    padding: 0;
    max-width: 35rem;
    margin-left: 0;
  }
`

const HeaderSocialIcons = styled.div`
  padding: 1.6rem 1.6rem;
  background: var(--light-green-darker);
  @media screen and (min-width: ${breakpoints.sm}px) {
    background: none;
    position: relative;
    padding-right: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    display: inline-block;
  }
  a {
    padding-right: 0.8rem;
    border-bottom: 0;
    &:link,
    &:visited,
    &:hover,
    &:active,
     {
      border-bottom: 0;
    }

    &:first-of-type {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      @media screen and (min-width: ${breakpoints.md}px) {
        padding-left: 0rem;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        padding-right: 2rem;
      }
    }
    svg {
      fill: var(--white);
      @media screen and (min-width: ${breakpoints.sm}px) {
        fill: var(--light-green-mid);
        &:hover { 
          background: var(--light-green-darkest);
          border-radius: 50%;
          path {
            fill: var(--white);

            &.outer-circle {
              display: none;
            }
          }
        } 
      }

      @media screen and (min-width: ${breakpoints.md}px) {
        width: 3.2rem;
        height: 3.2rem;

      }
    }
  }
`

export {
  HeaderContent,
  StyledHeader,
  Logo,
  Tagline,
  LogoButtonContainer,
  NavButton,
  NavMenu,
  NavMenuMdLarge,
  ParentNavList,
  ParentNavListItem,
  ChildNavListItemButton,
  ChildNavList,
  ChildNavListItem,
  HeaderSocialIcons
}
