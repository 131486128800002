import React, { useState, useEffect, useRef, useContext } from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Link } from "gatsby"
import { AuthContext } from "../components/layoutNoStripe";

import {
  StyledHeader,
  HeaderContent,
  NavButton,
  LogoButtonContainer,
  Logo,
  NavMenu,
  ChildNavList,
  ChildNavListItem,
  ParentNavListItem,
  ParentNavList,
  HeaderSocialIcons,
  NavMenuMdLarge
} from "../styledComponents/header"
import Icon from "../components/icon"
import KarinaGrantLogo from "./logo"
import { StyledButtonAsLink } from "../styledComponents/button";

const Header = ({ displayNavBorder, hasHero }) => {

  const authContext = useContext(AuthContext) || {};
  const breakpoints = useBreakpoint()

  const [isParentOpen, setIsParentOpen] = useState(false)
  const [isChildMenuOpen, setIsChildMenuOpen] = useState(false)
  
  const handleLogin = () => {
    authContext.loginWithRedirect({
      redirectUri: window.location.origin + '/my-account/',
    });
  };
  
  const wrapperRef = useRef(null)
  useHandleClickOutsideElement(wrapperRef)

  function useHandleClickOutsideElement(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsChildMenuOpen(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  return (
    <>
      <StyledHeader displayNavBorder={displayNavBorder}>
        <HeaderContent>
          <LogoButtonContainer>
            <Logo hasHero={hasHero}>
              <Link to="/" aria-label="KarinaGrant">
                {
                  breakpoints.xs || breakpoints.sm || breakpoints.md
                    ? isParentOpen && <KarinaGrantLogo /> || <KarinaGrantLogo hasHero={hasHero} />
                    : <KarinaGrantLogo hasHero={hasHero} />
                }
              </Link>
            </Logo>

            <NavButton
              tabIndex={
                breakpoints.xs || breakpoints.sm || breakpoints.md
                  ? "auto"
                  : "-1"
              }
              type="button"
              onClick={() => setIsParentOpen(!isParentOpen)}
              onBlur={() => setIsParentOpen(false)}
              isParentOpen={isParentOpen}
              aria-label="Menu"
            >
              <Icon name="nav" /> <span>{isParentOpen ? 'Close' : 'More'}</span>
            </NavButton>
          </LogoButtonContainer>
          {breakpoints.xs || breakpoints.sm || breakpoints.navMdL ?
            <></>
            :
            <NavMenuMdLarge>
              <ul>
                <li><Link to="/events">Workshops</Link></li>
                <li><Link to='/shop'>Shop</Link></li>
                <li><Link to='/testimonials'>Testimonials</Link></li>
                <li><Link to='/contact'>Contact</Link></li>
                {authContext.isAuthenticated ? <li><Link to='/my-account'><Icon name="user"/>My Account</Link></li> : <li><StyledButtonAsLink onClick={handleLogin}>Log in</StyledButtonAsLink></li>}
              </ul>
            </NavMenuMdLarge>
          }
          <NavMenu isParentOpen={isParentOpen} onFocus={() => setIsParentOpen(true)} onBlur={() => setIsParentOpen(false)}>
            <ParentNavList isParentOpen={isParentOpen}>
              {breakpoints.xs || breakpoints.sm ?
                <>
                  {authContext.isAuthenticated &&
                  <ParentNavListItem key="42x8jS"><Link to='/my-account'><Icon name="user"/>My Account</Link></ParentNavListItem>
                  }
                  <ParentNavListItem key="1k6de3"><Link to="/events">Workshops</Link></ParentNavListItem>
                  <ParentNavListItem key="21d22d"><Link to="/contact?booking">Make a booking?</Link></ParentNavListItem>
                  <ParentNavListItem key="7hgbve"><Link to="/contact">Contact</Link></ParentNavListItem>
                  <ParentNavListItem key="2de4f5"><Link to="/testimonials">Testimonials</Link></ParentNavListItem>
                  <ParentNavListItem key="0hyk21"><Link to="/quantum-touch/what-is-quantum-touch-used-for">Learn how to reduce &amp; overcome physical pain?</Link></ParentNavListItem>
                  <ParentNavListItem key="7ucj7a"><Link to="/product/permission-to-reset-anxiety/">Do you suffer with Anxiety?</Link></ParentNavListItem>
                  
                  <ParentNavListItem key="l0029i"><Link to="/shop">Shop - Meditations &amp; Programs</Link></ParentNavListItem>
                  <ParentNavListItem key="12uh6y"><Link to="/self-healing-mastery">Shop - Personal Development Program</Link></ParentNavListItem>

                  <ParentNavListItem key="u7t55f"><Link to="/permissioning-and-transformation/">What is Permissioning?</Link></ParentNavListItem>
                  {/* <ParentNavListItem key=""><Link to="/what-is-energy-healing">What is Energy Healing?</Link></ParentNavListItem> */}
                  <ParentNavListItem key="1ff34f"><Link to="/quantum-touch/what-is-quantum-touch-energy-healing">What is Quantum-Touch?</Link></ParentNavListItem>
                  <ParentNavListItem key="l90j8l"><Link to="/quantum-touch/what-is-quantum-touch-used-for">What is Quantum-Touch used for?</Link></ParentNavListItem>
                  <ParentNavListItem key="14fd2s"><Link to="/quantum-touch/what-is-self-created-health">Emotional Wellness</Link></ParentNavListItem>

                  <ParentNavListItem key="h76f45"><Link to="/corporate-wellness">Public speaking &amp; Wellness Talks</Link></ParentNavListItem>
                  <ParentNavListItem key="i9uu8y"><Link to='/mindfulness-meditation-phoneline/'>UK's 1st FREE Mindfulness Phoneline</Link></ParentNavListItem>
                  <ParentNavListItem key="0kk8jj"><Link to="/blog">Blog</Link></ParentNavListItem>
                  <ParentNavListItem key="0kk8jz"><Link to="/blog/tags/talks-&-media">Talks &amp; Media</Link></ParentNavListItem>
                  <ParentNavListItem key="8gvf54"><Link to="/about">About Karina</Link></ParentNavListItem>
                  <ParentNavListItem key="12x8j6" className="login-out">
                    {!authContext.isAuthenticated ?
                      <StyledButtonAsLink onClick={handleLogin}>Log in</StyledButtonAsLink>
                      :
                      <Link to='/' onClick={authContext.logout}>Log out</Link>
                    }
                  </ParentNavListItem>

                  </>
                :
                <>
                  <ParentNavListItem key="233xx2">
                    <h2>Information</h2>
                    <ChildNavList>
                      <ChildNavListItem key="km72yh"><Link to='/quantum-touch/what-is-quantum-touch-energy-healing'>Learn how to reduce &amp; overcome physical pain with Quantum-Touch?</Link></ChildNavListItem>
                      <ChildNavListItem key="2ed45t"><Link to="/quantum-touch/what-is-quantum-touch-energy-healing">What is Quantum-Touch energy healing?</Link></ChildNavListItem>
                      <ChildNavListItem key="cd5t6y"><Link to="/quantum-touch/what-is-quantum-touch-used-for">What is Quantum-Touch used for?</Link></ChildNavListItem>
                      <ChildNavListItem key="kl9879"><Link to="/quantum-touch/what-is-self-created-health">What is Self Created Health?</Link></ChildNavListItem>
                      <ChildNavListItem key="9iu76y"><Link to="/permissioning-and-transformation/">What is Permissioning?</Link></ChildNavListItem>
                      <ChildNavListItem key="kl8jmy"><Link to='/mindfulness-meditation-phoneline/'>UK's 1st FREE Mindfulness Phoneline</Link></ChildNavListItem>
                    </ChildNavList>

                    <h2>Shop</h2>
                    <ChildNavList>
                      <ChildNavListItem key="12x33x"><Link to="/shop">Audio Meditations &amp; Programs</Link></ChildNavListItem>
                      <ChildNavListItem key="x3d442"><Link to="/events">Workshops</Link></ChildNavListItem>
                      <ChildNavListItem key="j76gt6"><Link to="/product/permission-to-reset-anxiety/">Overcome Anxiety</Link></ChildNavListItem>
                      <ChildNavListItem key="zzsa12"><Link to="/self-healing-mastery">Personal Development</Link></ChildNavListItem>
                      <ChildNavListItem key="12s33d"><Link to="/quantum-touch/what-is-self-created-health">Emotional Wellness</Link></ChildNavListItem>
                    </ChildNavList>

                    <ChildNavList>
                      {/* <ChildNavListItem key="h76gty"><Link to="/testimonials">Testimonials</Link></ChildNavListItem> */}
                      <ChildNavListItem key="k98jui"><Link to="/corporate-wellness">Public speaking &amp; Wellness Talks</Link></ChildNavListItem>
                      <ChildNavListItem key="g56th7"><Link to="/contact?booking">Make a booking?</Link></ChildNavListItem>
                      <ChildNavListItem key="d4r5tg"><Link to='/about'>About Karina</Link></ChildNavListItem>
                      <ChildNavListItem key="h71az3"><Link to='/blog'>Blog</Link></ChildNavListItem>
                      <ChildNavListItem key="0zk8jz"><Link to="/blog/tags/talks-&-media">Talks &amp; Media</Link></ChildNavListItem>
                      {/* <ChildNavListItem key="8ju789"><Link to='/contact'>Contact</Link></ChildNavListItem> */}
                      <ChildNavListItem key="ko90hh">
                      {authContext.isAuthenticated && 
                          <Link to='/'
                            onClick={() => {authContext.logout({ returnTo: window.location.origin }); isParentOpen(false)}}>Log out {authContext.user?.given_name || "Guest" }</Link>
                            // onClick={() => { authContext.logout(); isParentOpen(false) }}>
                              
                        }
                      </ChildNavListItem>
                    </ChildNavList>
                  </ParentNavListItem>
                </>
              }

            </ParentNavList>
            <HeaderSocialIcons>
              <a href="https://www.facebook.com/karinagrantqt" target="_blank"><Icon name="facebook" /></a>
              <a href="https://www.linkedin.com/in/karina-grant-8b560659/" target="_blank"><Icon name="linkedin" /></a>
            </HeaderSocialIcons>
          </NavMenu>
        </HeaderContent>
      </StyledHeader>
    </>
  )
}

export default Header